<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
// import axios from "axios";
// import Swal from "sweetalert2";
// import moment from "moment";

export default {
    page: {
        title: "Daftar Banding",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        // Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        let columns = [
            { width: "50px", label: "No", name: "id_menu_master" },
            { width: "auto", label: "Nama Menu", name: "menu_master_nama" },
            { width: "100px", label: "Type", name: "menu_master_type" },
            { width: "125px", label: "Icon Menu", name: "menu_master_icon" },
            { width: "auto", label: "Link Path", name: "menu_master_link_path" },
            { width: "auto", label: "Link Name", name: "menu_master_link_name" },
            { width: "80px", label: "Urutan", name: "menu_master_urutan" },
            { width: "75px", label: "Parent", name: "menu_master_parent" },
            { width: "150px", label: "Created At", name: "created_at" },
            { width: "150px", label: "Updated At", name: "updated_at" },
            { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
            { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Rapat Banding",
            items: [
                {
                    text: "Dashboard",
                    href: "/",
                },    
                {
                    text: "Rapat Banding",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: true,
            table_data: [],
            columns: columns,
            sortKey: "id_menu_master", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: "ENABLE", // untuk filtering data
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
        };
    },
    mounted() {
    },
    methods: {

    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card-title">Daftar Banding</div>
                            </div>
                            <div class="col-md-12">
                                <hr />
                                <div class="row">
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Pilih Skema Sertifikasi</label>
                                        <select class="form-select" id="statusTable">
                                            <option value="">Pilih Skema Sertifikasi</option>
                                            <option value="">Skema Sertifikasi 1</option>
                                            <option value="">Skema Sertifikasi 2</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Pilih asesi</label>
                                        <select class="form-select" id="statusTable">
                                            <option value="">Pilih asesi</option>
                                            <option value="">Jonatan Sihotang</option>
                                            <option value="">Rizky Ramadhan</option>
                                            <option value="">Putra Pratama</option>
                                            <option value="">Muhammmad adhi</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-2 mt-4">
                                        <button class="btn btn-info btn-sm" style="margin-top:10px !important"><i
                                                class="fas fa-search"></i> Cari Data</button>
                                    </div>
                                    <div class="form-group col-md-6 mt-4 text-end">
                                        <div class="text-end">
                                            &nbsp;
                                            <button class="btn btn-sm btn-success m-1"><i class="fas fa-file-excel"></i> Cetak
                                                Excel</button>
                                            <button class="btn btn-sm btn-danger m-1"><i class="fas fa-file-pdf"></i> Cetak
                                                PDF</button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="col-md-12 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable"
                                                        style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable"
                                            placeholder="Cari Data ..." v-model="tableData.search"
                                            @input="getDataTable()" />
                                    </div>
                                </div>
                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                    <thead class="bg-dark text-center text-white">
                                        <tr>
                                            <th>No</th>
                                            <th>No Asesmen</th>
                                            <th>Nomer Surat Banding</th>
                                            <th>Skema Sertifikasi</th>
                                            <th>Asesi</th>
                                            <th>Asesor Kompetensi</th>
                                            <th>Asesor Banding</th>
                                            <th>Status</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-center">
                                        <tr>
                                            <td>1</td>
                                            <td>00078</td>
                                            <td>0058/LSP/MUK/II/2023</td>
                                            <td>Pelaksana Patologi dan Toksikologi</td>
                                            <td>Muhammad Rizki</td>
                                            <td>Muhammad ilham sagita Putra</td>
                                            <td>Joko saputro</td>
                                            <td><span class="badge bg-danger">Ditolak</span></td>
                                            <td class="text-center">
                                                <div class="d-grid gap-1">
                                                    <router-link :to="{ name: 'detail-banding-asesor', params: { id: 1 } }"
                                                        class="btn btn-sm btn-primary bg-primary"><i
                                                            class="fas fa-search"></i> Detail</router-link>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>2</td>
                                            <td>00080</td>
                                            <td>0080/LSP/MUK/II/2023</td>
                                            <td>Pelaksana Patologi dan Toksikologi</td>
                                            <td>Muhammad Surya Pratama</td>
                                            <td>Ade Surya</td>
                                            <td>Joko saputro</td>
                                            <td><span class="badge bg-warning">Proses banding</span></td>
                                            <td class="text-center">
                                                <div class="d-grid gap-1">
                                                    <router-link :to="{ name: 'detail-banding-asesor', params: { id: 2 } }"
                                                        class="btn btn-sm btn-primary bg-primary"><i
                                                            class="fas fa-search"></i> Detail</router-link>
                                                </div>
                                            </td>
                                        </tr>
                               
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
